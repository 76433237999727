import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query';
import { api } from '../utils/api';
import { useUserStore } from '../stores/UserStore';
import { toast } from 'react-toastify';
import { User } from '../utils/interfaces';
import { useTranslation } from 'react-i18next';
import {
  encryptData,
  saveData,
  getData,
  decryptData,
  deleteKeyStore,
} from '../utils/indexedDB';

// Fetch user from API and save to IndexedDB
const fetchUserFromApi = async (): Promise<User> => {
  const userDetails = await api.getUserDetails();
  const user: User = {
    UserID: userDetails.UserID,
    Username: userDetails.Username,
    Email: userDetails.Email,
    Phone: userDetails.Phone,
    Fullname: userDetails.Fullname || userDetails.Username,
    Address: userDetails.Address,
    City: userDetails.City,
    PostalCode: userDetails.PostalCode,
    Country: userDetails.Country,
    Currency: userDetails.Currency,
    UserRoleType: userDetails.UserRoleType,
    Password: '',
    Avatar: userDetails.Avatar,
    SubscriptionType: userDetails.SubscriptionType,
    EndDate: userDetails.EndDate,
    IsActive: userDetails.IsActive,
    VerificationExpiryDate: userDetails.VerificationExpiryDate,
    Gender: userDetails.Gender,
  };

  // Encrypt and save user data in IndexedDB
  const encryptedData = encryptData(JSON.stringify(user));
  await saveData('profileDetails', encryptedData);

  return user;
};

// Load user from IndexedDB
const loadUserFromIndexedDB = async (): Promise<User | null> => {
  const savedProfile = await getData('profileDetails');
  if (savedProfile) {
    const decryptedData = decryptData(savedProfile.value);
    return JSON.parse(decryptedData) as User;
  }
  return null;
};

export const useFetchUser = () => {
  const { setUser } = useUserStore();

  return useQuery<User>({
    queryKey: ['user'],
    queryFn: async () => {
      const userFromDB = await loadUserFromIndexedDB();

      if (userFromDB) {
        setUser(userFromDB);
        return userFromDB; // Return user from IndexedDB
      } else {
        const userFromApi = await fetchUserFromApi(); // Fetch from API if not in IndexedDB
        setUser(userFromApi);
        return userFromApi;
      }
    },
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
    placeholderData: keepPreviousData,
  });
};

// React Query hook to update user profile
export const useUpdateUserProfile = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setUser } = useUserStore();

  // Define the mutation function
  const mutation = useMutation({
    mutationFn: async (user: User) => {
      return await api.updateUserProfile(user);
    },
    onSuccess: async (updatedProfile: User) => {
      setUser(updatedProfile);
      queryClient.invalidateQueries({ queryKey: ['user'] });

      const encryptedData = encryptData(JSON.stringify(updatedProfile));
      await saveData('profileDetails', encryptedData);
      deleteKeyStore('profileDetails');
      toast.success(t('profile_update_success'));
    },
    onError: (error: Error) => {
      toast.error(t('profile_update_failure'));
      console.error('Error updating profile:', error);
    },
  });

  // Return the mutate function along with other mutation states
  return {
    ...mutation,
    updateUserProfile: mutation.mutate,
    updateUserProfileAsync: mutation.mutateAsync,
  };
};
